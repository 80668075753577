@tailwind base;
@tailwind components;
@tailwind utilities;

@import './icon.css';
@import './text.css';
@import 'swiper/css';
@import './font.css';

:root {
    /* --primary: #6e0d25; */
    --primary: #eb5713;
    --primary-light: #d04b11;
    --secondary: #fff0f1;

    --grey-0: #ffffff;
    --grey-50: #f1f1f1;
    --grey-100: #d3d3d3;
    --grey-200: #bdbdbd;
    --grey-300: #9f9f9f;
    --grey-400: #8d8d8d;
    --grey-500: #707070;
    --grey-600: #666666;
    --grey-700: #505050;
    --grey-800: #3e3e3e;
    --grey-900: #2f2f2f;
    --grey-1000: #d9d9d9;
    --grey-1100: #f8f8f8;

    --accent-red: #e04d4d;
    --accent-green: #2fb53d;
    --accent-light-green: #d8edde;
    --accent-orange: #ffb125;
    --accent-yellow: #fcd354;
    --accent-blue: #4987ed;
    --accent-light-blue: #f4f8ff;
    --accent-violet: #673ce0;

    font-family: 'Helvetica Now Text', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --background: #fafafa;

    --border-color: var(--grey-50);
    --transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    --a-hover-color: var(--primary);
    --font-family: 'Helvetica Now Text', Arial, Helvetica, sans-serif;

    /* Sections */
    --header-bg: #0f0f0f;
    --header-color: var(--grey-0);
    --navigation-bg: #0f0f0f;
    --navigation-color: var(--grey-0);
    --navigation-menu-item-hover-bg: var(--primary);
    --navigation-menu-item-hover-color: var(--grey-0);
    --navigation-menu-item-text-transform: capitalize;
    --navigation-menu-item-badge-bg: var(--primary);
    --navigation-menu-item-badge-color: var(--grey-0);

    --promotion-banner-bg: var(--primary);
    --promotion-banner-color: var(--grey-0);

    --footer-bg: #0f0f0f;
    --footer-title-color: var(--grey-0);
    --footer-color: var(--grey-0);
    --footer-social-icon-color: var(--grey-0);

    --tab-inactive-color: var(--grey-300);
    --tab-active-color: var(--primary);
    --tab-indicator-color: var(--border-color);
    --tab-active-indicator-color: var(--primary);

    --input-height: 40px;
    --input-border-color: var(--grey-100);

    --collection-item-order-color: var(--grey-900);
    --collection-item-name-color: var(--grey-900);

    --breadcrumb-color: var(--grey-700);

    --profile-layout-sidebar-item: var(--grey-900);
    --profile-layout-sidebar-item-hover: var(--primary);

    --avatar-bg: var(--primary);
    --avatar-text-color: var(--grey-0);

    --order-item-bg: #f9f9f9;

    --google-btn-bg: #0f0f0f;
    --google-btn-color: var(--grey-0);

    --product-item-add-to-cart-bg: #0f0f0f;
    --product-item-add-to-cart-color: var(--grey-0);

    --hot-badge-bg: var(--accent-orange);
    --new-badge-bg: var(--accent-red);
    --bestseller-badge-bg: var(--accent-red);

    --section-title-font-weight: 800;
    --section-title-text-transform: capitalize;
    --section-title-divider-color: #0f0f0f;
    --section-title-font-size: 2rem;
    --section-title-mobile-font-size: 1rem;

    --btn-border-radius: 0.25rem;

    --featured-product-border-radius: 0.25rem;

    --category-radio-item-color: #0f0f0f;

    --selected-item-border-color: #0f0f0f;

    --favorite-icon-color: #0f0f0f;

    --banner-border-radius: 0;
}

html {
    font-size: 16px;
    scroll-padding-top: 40px;
    scroll-behavior: smooth;
    line-height: 1.4;
}

body {
    color: var(--grey-800);
}
html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

p {
    margin-bottom: 1rem !important;
}

#nprogress .spinner {
    display: none !important;
}

#nprogress .peg {
    box-shadow: none !important;
}

.image-zoom-on-hover {
    overflow: hidden;
}

.image-zoom-on-hover img {
    transition: var(--transition);
}

.image-zoom-on-hover:hover img {
    transform: scale(1.05);
}

.dropdown-animation {
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(50px);
    visibility: hidden;
    pointer-events: none;
}

.dropdown-animation-show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    visibility: visible;
}

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
    opacity: 1;
}

.slick-prev,
.slick-next {
    background: rgba(62, 62, 62, 0.6) !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: white !important;
    z-index: 20;
    opacity: 0;
    transition: var(--transition);
}

.slick-prev {
    left: 0 !important;
}

.slick-next {
    right: 0 !important;
}

.slick-prev::before,
.slick-next::before {
    display: none;
}
.slick-slide {
    padding: 0 5px;
}
.slick-track {
    display: flex;
    flex-wrap: nowrap;
}

.lightbox-container {
    z-index: 1000 !important;
    overflow: hidden;
}

.lightbox-image-pager {
    background-color: rgba(0, 0, 0, 0.8);
}
/* .slick-slider{
  margin-bottom: 20px;
} */
.slick-dots {
    bottom: -20px;
}

.slick-dots li.slick-active button:before {
    color: var(--primary);
}

.slick-dots li {
    width: 10px;
    margin: 0;
}
.text-error {
    color: var(--accent-red);
}
.paypal-powered-by {
    display: none !important;
}
.fill {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}

.scrollbar-cs::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
}

.scrollbar-cs::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #cccccc;
    background-color: #f5f5f5;
}

.scrollbar-cs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #cccccc;
    background-color: #ccc;
}

@media only screen and (min-width: 300px) {
    .paypal-powered-by {
        display: none !important;
    }
}
@media only screen and (min-width: 1200px) {
    .paypal-powered-by {
        display: block;
    }
}

.cms-page {
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }
    ul > li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
    }
}

/* swiper */
.swiper:hover .swiper-button-prev, .swiper:hover .swiper-button-next 
{
  opacity: 1;
  visibility: visible;
}
.swiper:hover .swiper-button-prev.swiper-button-disabled,
.swiper:hover .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    pointer-events: visible;
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--primary) !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 0px #0000004d, 0px 4px 8px 3px #00000026;
    border-radius: 100%;
    width: 33px !important;
    height: 33px !important;
    transition: var(--transition);
    z-index: 11 !important;
    display: none;
    opacity: 0;
    visibility: hidden;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    transform: scale(1.5);
    transition: var(--transition);
}

.swiper-button-prev {
    background-image: url('/svgs/arrow-left-2.svg');
}
.swiper-button-next {
    background-image: url('/svgs/arrow-right-2.svg');
}
.swiper-button-prev::after,
.swiper-button-next::after {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .swiper-button-prev,
    .swiper-button-next {
        display: block;
    }
}

div#cxgenie-chat-button .cxgenie-chat-button.open,
#cxgenie-chat-button .cxgenie-chat-modal.open {
    display: none !important;
    opacity: 0 !important;
}

@media only screen and (min-width: 769px) {
    .blog-base {
        font-size: 18px !important;
        line-height: 30px !important;
    }

    .blog-base .Text_body__xH0e9 {
        font-size: 18px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width: 768px) {
    .blog-base {
        font-size: 16px !important;
        line-height: 30px !important;
    }

    .blog-base .Text_body__xH0e9 {
        font-size: 16px !important;
        line-height: 30px !important;
    }
}
.app-overlay {
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
}
.show-overlay .app-overlay {
    opacity: 0.5;
    visibility: visible;
    transition: var(--transition);
}

#___ratingbadge_0{
    position: relative !important;
    z-index: 2 !important;
}

div#___ratingbadge_0 > iframe {
    width: 200px !important;
}

.responsive-img {
    max-width: 100%;
    height: auto;
}
