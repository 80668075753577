.blocks {
    color: #5f6368;
    font-size: 16px;
    line-height: 30px;
}

.blocks h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
}
.blocks h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}
.blocks h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
}

.blocks h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.blocks ol,
.blocks ul {
    list-style: revert;
    padding-left: 1rem;
    margin-left: 1rem;
}

.blocks label,
.blocks input,
.blocks textarea,
.blocks button {
    font-size: 16px !important;
    line-height: 30px !important;
}

@media (min-width: 1024px) {
    .blocks {
        font-size: 18px;
        line-height: 30px;
    }
    .blocks h1 {
        font-size: 40px;
        line-height: 50px;
    }
    .blocks h2 {
        font-size: 35px;
        line-height: 45px;
    }
    .blocks h3 {
        font-size: 28px;
        line-height: 38px;
    }
    .blocks h4 {
        font-size: 20px;
        line-height: 30px;
    }

    .blocks label,
    .blocks input,
    .blocks textarea,
    .blocks button {
        font-size: 18px !important;
        line-height: 30px !important;
    }
}
@media (min-width: 768px) {
    .blocks {
        font-size: 18px;
        line-height: 30px;
    }
    .blocks h1 {
        font-size: 40px;
        line-height: 50px;
    }
    .blocks h2 {
        font-size: 35px;
        line-height: 48px;
    }
    .blocks h3 {
        font-size: 24px;
        line-height: 28px;
    }
    .blocks h4 {
        font-size: 20px;
        line-height: 28px;
    }
}
