@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-Black.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-Black.woff") format("woff"),
    url("/fonts/HelveticaNowText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-Bold.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-Bold.woff") format("woff"),
    url("/fonts/HelveticaNowText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-ExtraBold.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-ExtraBold.woff") format("woff"),
    url("/fonts/HelveticaNowText-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-Regular.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-Regular.woff") format("woff"),
    url("/fonts/HelveticaNowText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("/fonts/HelveticaNowText-Medium.woff2") format("woff2"),
    url("/fonts/HelveticaNowText-Medium.woff") format("woff"),
    url("/fonts/HelveticaNowText-Medium.ttf") format("truetype"),
    url("/fonts/HelveticaNowText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
