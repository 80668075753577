@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?n0g1wf");
  src: url("/fonts/icomoon.eot?n0g1wf#iefix") format("embedded-opentype"),ß
    url("/fonts/icomoon.woff?n0g1wf") format("woff"),
    url("/fonts/icomoon.woff2?n0g1wf") format("woff2"),
    url("/fonts/icomoon.svg?n0g1wf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star-2:before {
  content: "\ed0c";
}
.icon-help:before {
  content: "\ed08";
}
.icon-thumb-down:before {
  content: "\ed02";
}
.icon-thumb-up:before {
  content: "\ed03";
}
.icon-seemore:before {
  content: "\ea7a";
}
.icon-support-24:before {
  content: "\ed00";
}
.icon-simple-icons_x:before {
  content: "\ecff";
}
.icon-linkedin:before {
  content: "\ecfe";
}
.icon-heart-fill:before {
  content: "\ed0b";
}
.icon-arrow-up-right:before {
  content: "\ed0a";
}
.icon-data-base:before {
  content: "\ed09";
}
.icon-p-high:before {
  content: "\e91b";
}
.icon-p-low:before {
  content: "\ed06";
}
.icon-p-medium:before {
  content: "\ed07";
}
.icon-tick-double:before {
  content: "\ed04";
}
.icon-tick:before {
  content: "\ed05";
}
.icon-chatbot:before {
  content: "\ed01";
}
.icon-bug1:before {
  content: "\ecf9";
}
.icon-facebook .path1:before {
  content: "\e900";
  color: rgb(24, 119, 242);
}
.icon-facebook .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-google .path1:before {
  content: "\e902";
  color: rgb(66, 133, 244);
}
.icon-google .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-google .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-google .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(235, 67, 53);
}
.icon-aave-aave:before {
  content: "\e906";
}
.icon-activity:before {
  content: "\e907";
}
.icon-airdrop:before {
  content: "\e908";
}
.icon-airpod:before {
  content: "\e909";
}
.icon-airpods:before {
  content: "\e90a";
}
.icon-android:before {
  content: "\e90b";
}
.icon-ankr-ankr:before {
  content: "\e90c";
}
.icon-apple:before {
  content: "\e90d";
}
.icon-aquarius:before {
  content: "\e90e";
}
.icon-archive-add:before {
  content: "\e90f";
}
.icon-archive-minus:before {
  content: "\e910";
}
.icon-archive-slash:before {
  content: "\e911";
}
.icon-archive-tick:before {
  content: "\e912";
}
.icon-archive:before {
  content: "\e913";
}
.icon-augur-rep:before {
  content: "\e914";
}
.icon-autonio-niox:before {
  content: "\e915";
}
.icon-avalanche-avax:before {
  content: "\e916";
}
.icon-bank:before {
  content: "\e917";
}
.icon-be:before {
  content: "\e918";
}
.icon-binance-usd-busd:before {
  content: "\e91a";
}
.icon-blogger:before {
  content: "\e91c";
}
.icon-bluetooth-2:before {
  content: "\e91d";
}
.icon-bluetooth-circle:before {
  content: "\e91e";
}
.icon-bluetooth-rectangle:before {
  content: "\e91f";
}
.icon-bluetooth:before {
  content: "\e920";
}
.icon-book-saved:before {
  content: "\e921";
}
.icon-book-square:before {
  content: "\e922";
}
.icon-bootstrap:before {
  content: "\e923";
}
.icon-bug:before {
  content: "\e924";
}
.icon-building-3:before {
  content: "\e925";
}
.icon-building-4:before {
  content: "\e926";
}
.icon-building:before {
  content: "\e927";
}
.icon-buildings-2:before {
  content: "\e928";
}
.icon-buildings:before {
  content: "\e929";
}
.icon-buliding:before {
  content: "\e92a";
}
.icon-calendar-1:before {
  content: "\e92b";
}
.icon-calendar-2:before {
  content: "\e92c";
}
.icon-calendar-add:before {
  content: "\e92d";
}
.icon-calendar-circle:before {
  content: "\e92e";
}
.icon-calendar-edit:before {
  content: "\e92f";
}
.icon-calendar-remove:before {
  content: "\e930";
}
.icon-calendar-search:before {
  content: "\e931";
}
.icon-calendar-tick:before {
  content: "\e932";
}
.icon-calendar:before {
  content: "\e933";
}
.icon-call-add:before {
  content: "\e934";
}
.icon-call-calling:before {
  content: "\e935";
}
.icon-call-incoming:before {
  content: "\e936";
}
.icon-call-minus:before {
  content: "\e937";
}
.icon-call-outgoing:before {
  content: "\e938";
}
.icon-call-received:before {
  content: "\e939";
}
.icon-call-remove:before {
  content: "\e93a";
}
.icon-call-slash:before {
  content: "\e93b";
}
.icon-call:before {
  content: "\e93c";
}
.icon-candle-2:before {
  content: "\e93d";
}
.icon-candle:before {
  content: "\e93e";
}
.icon-cardano-ada:before {
  content: "\e93f";
}
.icon-category-2:before {
  content: "\e940";
}
.icon-category:before {
  content: "\e941";
}
.icon-celo-celo:before {
  content: "\e942";
}
.icon-celsius-cel:before {
  content: "\e943";
}
.icon-chainlink-link:before {
  content: "\e944";
}
.icon-chart-1:before {
  content: "\e945";
}
.icon-chart-2:before {
  content: "\e946";
}
.icon-chart-21:before {
  content: "\e947";
}
.icon-chart-fail:before {
  content: "\e948";
}
.icon-chart-success:before {
  content: "\e949";
}
.icon-chart:before {
  content: "\e94a";
}
.icon-civic-cvc:before {
  content: "\e94b";
}
.icon-clock-1:before {
  content: "\e94c";
}
.icon-clock:before {
  content: "\e94d";
}
.icon-cloud-add:before {
  content: "\e94e";
}
.icon-cloud-change:before {
  content: "\e94f";
}
.icon-cloud-connection:before {
  content: "\e950";
}
.icon-cloud-remove:before {
  content: "\e951";
}
.icon-courthouse:before {
  content: "\e952";
}
.icon-cpu-charge:before {
  content: "\e953";
}
.icon-cpu-setting:before {
  content: "\e954";
}
.icon-cpu:before {
  content: "\e955";
}
.icon-dai-dai:before {
  content: "\e956";
}
.icon-dash-dash:before {
  content: "\e957";
}
.icon-decred-dcr:before {
  content: "\e958";
}
.icon-dent-dent:before {
  content: "\e959";
}
.icon-devices:before {
  content: "\e95a";
}
.icon-diagram:before {
  content: "\e95b";
}
.icon-dribbble:before {
  content: "\e95c";
}
.icon-driver-2:before {
  content: "\e95d";
}
.icon-driver-refresh:before {
  content: "\e95e";
}
.icon-driver:before {
  content: "\e95f";
}
.icon-dropbox:before {
  content: "\e960";
}
.icon-educare-ekt:before {
  content: "\e961";
}
.icon-electricity:before {
  content: "\e962";
}
.icon-emercoin-emc:before {
  content: "\e963";
}
.icon-enjin-coin-enj:before {
  content: "\e964";
}
.icon-eos-eos:before {
  content: "\e965";
}
.icon-ethereum-eth:before {
  content: "\e966";
}
.icon-ethereum-classic-etc:before {
  content: "\e967";
}
.icon-external-drive:before {
  content: "\e968";
}
.icon-facebook1:before {
  content: "\e969";
}
.icon-favorite-chart:before {
  content: "\e96a";
}
.icon-figma-1:before {
  content: "\e96b";
}
.icon-figma:before {
  content: "\e96c";
}
.icon-folder-connection:before {
  content: "\e96d";
}
.icon-framer:before {
  content: "\e96e";
}
.icon-ftx-token-ftt:before {
  content: "\e96f";
}
.icon-game:before {
  content: "\e970";
}
.icon-gameboy:before {
  content: "\e971";
}
.icon-gemini-2:before {
  content: "\e972";
}
.icon-gemini:before {
  content: "\e973";
}
.icon-google-drive:before {
  content: "\e974";
}
.icon-google-paly:before {
  content: "\e975";
}
.icon-google1:before {
  content: "\e976";
}
.icon-graph:before {
  content: "\e977";
}
.icon-harmony-one:before {
  content: "\e978";
}
.icon-hashtag:before {
  content: "\e979";
}
.icon-headphone:before {
  content: "\e97a";
}
.icon-headphones:before {
  content: "\e97b";
}
.icon-health:before {
  content: "\e97c";
}
.icon-hedera-hashgraph-hbar:before {
  content: "\e97d";
}
.icon-hex-hex:before {
  content: "\e97e";
}
.icon-home-hashtag:before {
  content: "\e97f";
}
.icon-home-trend-down:before {
  content: "\e980";
}
.icon-home-trend-up:before {
  content: "\e981";
}
.icon-hospital:before {
  content: "\e982";
}
.icon-house-2:before {
  content: "\e983";
}
.icon-house:before {
  content: "\e984";
}
.icon-html-3:before {
  content: "\e985";
}
.icon-html-5:before {
  content: "\e986";
}
.icon-huobi-token-ht:before {
  content: "\e987";
}
.icon-icon-icx:before {
  content: "\e988";
}
.icon-icon-1:before {
  content: "\e989";
}
.icon-icon:before {
  content: "\e98a";
}
.icon-illustrator:before {
  content: "\e98b";
}
.icon-iost-iost:before {
  content: "\e98c";
}
.icon-java-script:before {
  content: "\e98d";
}
.icon-js:before {
  content: "\e98e";
}
.icon-keyboard-open:before {
  content: "\e98f";
}
.icon-keyboard:before {
  content: "\e990";
}
.icon-kyber-network-knc:before {
  content: "\e991";
}
.icon-lamp-charge:before {
  content: "\e992";
}
.icon-lamp-on:before {
  content: "\e993";
}
.icon-lamp-slash:before {
  content: "\e994";
}
.icon-lamp:before {
  content: "\e995";
}
.icon-litecoinltc:before {
  content: "\e996";
}
.icon-maker-mkr:before {
  content: "\e997";
}
.icon-man:before {
  content: "\e998";
}
.icon-menu:before {
  content: "\e999";
}
.icon-messenger:before {
  content: "\e99a";
}
.icon-microscope:before {
  content: "\e99b";
}
.icon-mirroring-screen:before {
  content: "\e99c";
}
.icon-mobile:before {
  content: "\e99d";
}
.icon-monero-xmr:before {
  content: "\e99e";
}
.icon-monitor-mobbile:before {
  content: "\e99f";
}
.icon-monitor-recorder:before {
  content: "\e9a0";
}
.icon-monitor:before {
  content: "\e9a1";
}
.icon-more-2-1:before {
  content: "\e9a2";
}
.icon-more-2:before {
  content: "\e9a3";
}
.icon-more-3:before {
  content: "\e9a4";
}
.icon-more:before {
  content: "\e9a5";
}
.icon-mouse:before {
  content: "\e9a6";
}
.icon-music-play:before {
  content: "\e9a7";
}
.icon-nebulas-nas:before {
  content: "\e9a8";
}
.icon-nem-xem:before {
  content: "\e9a9";
}
.icon-nexo-nexo:before {
  content: "\e9aa";
}
.icon-notification-1:before {
  content: "\e9ab";
}
.icon-notification-bing:before {
  content: "\e9ac";
}
.icon-notification-circle:before {
  content: "\e9ad";
}
.icon-notification-favorite:before {
  content: "\e9ae";
}
.icon-notification-status:before {
  content: "\e9af";
}
.icon-notification:before {
  content: "\e9b0";
}
.icon-ocean-protocol-ocean:before {
  content: "\e9b1";
}
.icon-okb-okb:before {
  content: "\e9b2";
}
.icon-ontology-ont:before {
  content: "\e9b3";
}
.icon-paypal:before {
  content: "\e9b4";
}
.icon-personalcard:before {
  content: "\e9b5";
}
.icon-photoshop:before {
  content: "\e9b6";
}
.icon-polkadot-dot:before {
  content: "\e9b7";
}
.icon-polygon-matic:before {
  content: "\e9b8";
}
.icon-polyswarm-nct:before {
  content: "\e9b9";
}
.icon-presention-chart:before {
  content: "\e9ba";
}
.icon-printer-slash:before {
  content: "\e9bb";
}
.icon-printer:before {
  content: "\e9bc";
}
.icon-python:before {
  content: "\e9bd";
}
.icon-quant-qnt:before {
  content: "\e9be";
}
.icon-ram-2:before {
  content: "\e9bf";
}
.icon-ram:before {
  content: "\e9c0";
}
.icon-receipt-square:before {
  content: "\e9c1";
}
.icon-sagittarius:before {
  content: "\e9c2";
}
.icon-save-2:before {
  content: "\e9c3";
}
.icon-save-add:before {
  content: "\e9c4";
}
.icon-save-minus:before {
  content: "\e9c5";
}
.icon-save-remove:before {
  content: "\e9c6";
}
.icon-search-favorite-1:before {
  content: "\e9c7";
}
.icon-search-favorite:before {
  content: "\e9c8";
}
.icon-search-normal-1:before {
  content: "\e9c9";
}
.icon-search-normal:before {
  content: "\e9ca";
}
.icon-search-status-1:before {
  content: "\e9cb";
}
.icon-search-status:before {
  content: "\e9cc";
}
.icon-search-zoom-in-1:before {
  content: "\e9cd";
}
.icon-search-zoom-in:before {
  content: "\e9ce";
}
.icon-search-zoom-out-1:before {
  content: "\e9cf";
}
.icon-search-zoom-out:before {
  content: "\e9d0";
}
.icon-security-time:before {
  content: "\e9d1";
}
.icon-setting-2:before {
  content: "\e9d2";
}
.icon-setting-3:before {
  content: "\e9d3";
}
.icon-setting-4:before {
  content: "\e9d4";
}
.icon-setting-5:before {
  content: "\e9d5";
}
.icon-setting:before {
  content: "\e9d6";
}
.icon-settings:before {
  content: "\e9d7";
}
.icon-siacoin-sc:before {
  content: "\e9d8";
}
.icon-simcard-1:before {
  content: "\e9d9";
}
.icon-simcard-2:before {
  content: "\e9da";
}
.icon-simcard:before {
  content: "\e9db";
}
.icon-slack:before {
  content: "\e9dc";
}
.icon-snapchat:before {
  content: "\e9dd";
}
.icon-solana-sol:before {
  content: "\e9de";
}
.icon-speaker:before {
  content: "\e9df";
}
.icon-spotify:before {
  content: "\e9e0";
}
.icon-stacks-stx:before {
  content: "\e9e1";
}
.icon-status-up:before {
  content: "\e9e2";
}
.icon-stellar-xlm:before {
  content: "\e9e3";
}
.icon-tenx-pay:before {
  content: "\e9e4";
}
.icon-tether-usdt:before {
  content: "\e9e5";
}
.icon-the-graph-grt:before {
  content: "\e9e6";
}
.icon-theta-theta:before {
  content: "\e9e7";
}
.icon-thorchain-rune:before {
  content: "\e9e8";
}
.icon-timer-1:before {
  content: "\e9e9";
}
.icon-timer-pause:before {
  content: "\e9ea";
}
.icon-timer-start:before {
  content: "\e9eb";
}
.icon-timer:before {
  content: "\e9ec";
}
.icon-toggle-off-circle:before {
  content: "\e9ed";
}
.icon-toggle-off:before {
  content: "\e9ee";
}
.icon-toggle-on-circle:before {
  content: "\e9ef";
}
.icon-toggle-on:before {
  content: "\e9f0";
}
.icon-trello:before {
  content: "\e9f1";
}
.icon-trend-down:before {
  content: "\e9f2";
}
.icon-trend-up:before {
  content: "\e9f3";
}
.icon-triangle:before {
  content: "\e9f4";
}
.icon-trontron-trx:before {
  content: "\e9f5";
}
.icon-twitch:before {
  content: "\e9f6";
}
.icon-ui8:before {
  content: "\e9f7";
}
.icon-usd-coin-usdc:before {
  content: "\e9f8";
}
.icon-velas-vlx:before {
  content: "\e9f9";
}
.icon-vibe-vibe:before {
  content: "\e9fa";
}
.icon-vuesax:before {
  content: "\e9fb";
}
.icon-wanchain-wan-1:before {
  content: "\e9fc";
}
.icon-wanchain-wan:before {
  content: "\e9fd";
}
.icon-watch-status:before {
  content: "\e9fe";
}
.icon-watch:before {
  content: "\e9ff";
}
.icon-weight:before {
  content: "\ea00";
}
.icon-whatsapp:before {
  content: "\ea01";
}
.icon-windows:before {
  content: "\ea02";
}
.icon-wing-wing:before {
  content: "\ea03";
}
.icon-woman:before {
  content: "\ea04";
}
.icon-xd:before {
  content: "\ea05";
}
.icon-xiaomi:before {
  content: "\ea06";
}
.icon-xrp-xrp:before {
  content: "\ea07";
}
.icon-youtube:before {
  content: "\ea08";
}
.icon-zel-zel:before {
  content: "\ea09";
}
.icon-zoom:before {
  content: "\ea0a";
}
.icon-d-cube-scan:before {
  content: "\ea0b";
}
.icon-d-rotate:before {
  content: "\ea0c";
}
.icon-d-square:before {
  content: "\ea0d";
}
.icon-dcube:before {
  content: "\ea0e";
}
.icon-square:before {
  content: "\ea0f";
}
.icon-support:before {
  content: "\ea10";
}
.icon-add-circle:before {
  content: "\ea11";
}
.icon-add-square:before {
  content: "\ea12";
}
.icon-add:before {
  content: "\ea13";
}
.icon-additem:before {
  content: "\ea14";
}
.icon-alarm:before {
  content: "\ea15";
}
.icon-align-bottom:before {
  content: "\ea16";
}
.icon-align-horizontally:before {
  content: "\ea17";
}
.icon-align-left:before {
  content: "\ea18";
}
.icon-align-right:before {
  content: "\ea19";
}
.icon-align-vertically:before {
  content: "\ea1a";
}
.icon-archive-book:before {
  content: "\ea1b";
}
.icon-archive1:before {
  content: "\ea1c";
}
.icon-autobrightness:before {
  content: "\ea1f";
}
.icon-award:before {
  content: "\ea20";
}
.icon-backward-item:before {
  content: "\ea21";
}
.icon-bag-2:before {
  content: "\ea22";
}
.icon-bag-cross-1:before {
  content: "\ea23";
}
.icon-bag-cross:before {
  content: "\ea24";
}
.icon-bag-happy:before {
  content: "\ea25";
}
.icon-bag-tick-2:before {
  content: "\ea26";
}
.icon-bag-tick:before {
  content: "\ea27";
}
.icon-bag-timer:before {
  content: "\ea28";
}
.icon-bag:before {
  content: "\ea29";
}
.icon-barcode:before {
  content: "\ea2a";
}
.icon-battery-charging:before {
  content: "\ea2b";
}
.icon-battery-disable:before {
  content: "\ea2c";
}
.icon-battery-empty-1:before {
  content: "\ea2d";
}
.icon-battery-empty:before {
  content: "\ea2e";
}
.icon-battery-full:before {
  content: "\ea2f";
}
.icon-bezier:before {
  content: "\ea30";
}
.icon-bill:before {
  content: "\ea31";
}
.icon-bitcoin-card:before {
  content: "\ea32";
}
.icon-bitcoin-convert:before {
  content: "\ea33";
}
.icon-bitcoin-refresh:before {
  content: "\ea34";
}
.icon-blend-2:before {
  content: "\ea35";
}
.icon-blend:before {
  content: "\ea36";
}
.icon-blur:before {
  content: "\ea37";
}
.icon-book-1:before {
  content: "\ea38";
}
.icon-book:before {
  content: "\ea39";
}
.icon-bookmark-2:before {
  content: "\ea3a";
}
.icon-bookmark:before {
  content: "\ea3b";
}
.icon-box-1:before {
  content: "\ea3c";
}
.icon-box-2:before {
  content: "\ea3d";
}
.icon-box-add:before {
  content: "\ea3e";
}
.icon-box-remove:before {
  content: "\ea3f";
}
.icon-box-search:before {
  content: "\ea40";
}
.icon-box-tick:before {
  content: "\ea41";
}
.icon-box-time:before {
  content: "\ea42";
}
.icon-box:before {
  content: "\ea43";
}
.icon-briefcase:before {
  content: "\ea44";
}
.icon-brifecase-cross:before {
  content: "\ea45";
}
.icon-brifecase-tick:before {
  content: "\ea46";
}
.icon-brifecase-timer:before {
  content: "\ea47";
}
.icon-broom:before {
  content: "\ea48";
}
.icon-brush-1:before {
  content: "\ea49";
}
.icon-brush-2:before {
  content: "\ea4a";
}
.icon-brush-3:before {
  content: "\ea4b";
}
.icon-brush-4:before {
  content: "\ea4c";
}
.icon-brush:before {
  content: "\ea4d";
}
.icon-bubble:before {
  content: "\ea4e";
}
.icon-bucket-circle:before {
  content: "\ea4f";
}
.icon-bucket-square:before {
  content: "\ea50";
}
.icon-bucket:before {
  content: "\ea51";
}
.icon-buy-crypto:before {
  content: "\ea52";
}
.icon-cake:before {
  content: "\ea53";
}
.icon-calculator:before {
  content: "\ea54";
}
.icon-card-coin:before {
  content: "\ea55";
}
.icon-cd:before {
  content: "\ea56";
}
.icon-chart1:before {
  content: "\ea57";
}
.icon-check:before {
  content: "\ea58";
}
.icon-chrome:before {
  content: "\ea59";
}
.icon-clipboard-close:before {
  content: "\ea5a";
}
.icon-clipboard-export:before {
  content: "\ea5b";
}
.icon-clipboard-import:before {
  content: "\ea5c";
}
.icon-clipboard-text:before {
  content: "\ea5d";
}
.icon-clipboard-tick:before {
  content: "\ea5e";
}
.icon-clipboard:before {
  content: "\ea5f";
}
.icon-close-circle:before {
  content: "\ea60";
}
.icon-close-square:before {
  content: "\ea61";
}
.icon-close:before {
  content: "\ea62";
}
.icon-code-1:before {
  content: "\ea63";
}
.icon-code-circle:before {
  content: "\ea64";
}
.icon-code:before {
  content: "\ea65";
}
.icon-coffee:before {
  content: "\ea66";
}
.icon-color-swatch:before {
  content: "\ea67";
}
.icon-colorfilter:before {
  content: "\ea68";
}
.icon-colors-square:before {
  content: "\ea69";
}
.icon-command-square:before {
  content: "\ea6a";
}
.icon-command:before {
  content: "\ea6b";
}
.icon-component:before {
  content: "\ea6c";
}
.icon-computing:before {
  content: "\ea6d";
}
.icon-convert-3d-cube:before {
  content: "\ea6e";
}
.icon-convertshape-2:before {
  content: "\ea6f";
}
.icon-convertshape:before {
  content: "\ea70";
}
.icon-copy-success:before {
  content: "\ea71";
}
.icon-copy:before {
  content: "\ea72";
}
.icon-copyright:before {
  content: "\ea73";
}
.icon-creative-commons:before {
  content: "\ea74";
}
.icon-crop:before {
  content: "\ea75";
}
.icon-crown-1:before {
  content: "\ea76";
}
.icon-crown:before {
  content: "\ea77";
}
.icon-cup:before {
  content: "\ea78";
}
.icon-danger:before {
  content: "\ea79";
}
.icon-data:before {
  content: "\ea7b";
}
.icon-designtools:before {
  content: "\ea7c";
}
.icon-device-message:before {
  content: "\ea7d";
}
.icon-diamonds:before {
  content: "\ea7e";
}
.icon-direct-down:before {
  content: "\ea7f";
}
.icon-direct-inbox:before {
  content: "\ea80";
}
.icon-direct-left:before {
  content: "\ea81";
}
.icon-direct-normal:before {
  content: "\ea82";
}
.icon-direct-notification:before {
  content: "\ea83";
}
.icon-direct-right:before {
  content: "\ea84";
}
.icon-direct-send:before {
  content: "\ea85";
}
.icon-direct-up:before {
  content: "\ea86";
}
.icon-direct:before {
  content: "\ea87";
}
.icon-directbox-default:before {
  content: "\ea88";
}
.icon-directbox-notif:before {
  content: "\ea89";
}
.icon-directbox-receive:before {
  content: "\ea8a";
}
.icon-directbox-send:before {
  content: "\ea8b";
}
.icon-discover-1:before {
  content: "\ea8c";
}
.icon-discover:before {
  content: "\ea8d";
}
.icon-dislike:before {
  content: "\ea8e";
}
.icon-document-cloud:before {
  content: "\ea8f";
}
.icon-document-code-2:before {
  content: "\ea90";
}
.icon-document-code:before {
  content: "\ea91";
}
.icon-document-copy:before {
  content: "\ea92";
}
.icon-document-download:before {
  content: "\ea93";
}
.icon-document-favorite:before {
  content: "\ea94";
}
.icon-document-filter:before {
  content: "\ea95";
}
.icon-document-forward:before {
  content: "\ea96";
}
.icon-document-like:before {
  content: "\ea97";
}
.icon-document-normal:before {
  content: "\ea98";
}
.icon-document-previous:before {
  content: "\ea99";
}
.icon-document-sketch:before {
  content: "\ea9a";
}
.icon-document-text-1:before {
  content: "\ea9b";
}
.icon-document-text:before {
  content: "\ea9c";
}
.icon-document-upload:before {
  content: "\ea9d";
}
.icon-document:before {
  content: "\ea9e";
}
.icon-edit-2:before {
  content: "\ea9f";
}
.icon-edit:before {
  content: "\eaa0";
}
.icon-element-2:before {
  content: "\eaa1";
}
.icon-element-3:before {
  content: "\eaa2";
}
.icon-element-4:before {
  content: "\eaa3";
}
.icon-element-equal:before {
  content: "\eaa4";
}
.icon-element-plus:before {
  content: "\eaa5";
}
.icon-emoji-happy:before {
  content: "\eaa6";
}
.icon-emoji-normal:before {
  content: "\eaa7";
}
.icon-emoji-sad:before {
  content: "\eaa8";
}
.icon-eraser:before {
  content: "\eaa9";
}
.icon-eye-slash:before {
  content: "\eaaa";
}
.icon-eye:before {
  content: "\eaab";
}
.icon-fatrows:before {
  content: "\eaac";
}
.icon-filter-add:before {
  content: "\eaad";
}
.icon-filter-edit:before {
  content: "\eaae";
}
.icon-filter-remove:before {
  content: "\eaaf";
}
.icon-filter-search:before {
  content: "\eab0";
}
.icon-filter-square:before {
  content: "\eab1";
}
.icon-filter-tick:before {
  content: "\eab2";
}
.icon-filter:before {
  content: "\eab3";
}
.icon-finger-cricle:before {
  content: "\eab4";
}
.icon-finger-scan:before {
  content: "\eab5";
}
.icon-flag-2:before {
  content: "\eab6";
}
.icon-flag:before {
  content: "\eab7";
}
.icon-flash-circle-1:before {
  content: "\eab8";
}
.icon-flash-circle:before {
  content: "\eab9";
}
.icon-flash-slash:before {
  content: "\eaba";
}
.icon-flash:before {
  content: "\eabb";
}
.icon-forbidden-2:before {
  content: "\eabc";
}
.icon-forbidden:before {
  content: "\eabd";
}
.icon-format-circle:before {
  content: "\eabe";
}
.icon-format-square:before {
  content: "\eabf";
}
.icon-forward-item:before {
  content: "\eac0";
}
.icon-frame-1:before {
  content: "\eac1";
}
.icon-frame-2:before {
  content: "\eac2";
}
.icon-frame:before {
  content: "\eac3";
}
.icon-ghost:before {
  content: "\eac4";
}
.icon-gift:before {
  content: "\eac5";
}
.icon-glass-1:before {
  content: "\eac6";
}
.icon-glass:before {
  content: "\eac7";
}
.icon-global-edit:before {
  content: "\eac8";
}
.icon-global-refresh:before {
  content: "\eac9";
}
.icon-global-search:before {
  content: "\eaca";
}
.icon-global:before {
  content: "\eacb";
}
.icon-gps-slash:before {
  content: "\eacc";
}
.icon-gps:before {
  content: "\eacd";
}
.icon-grammerly:before {
  content: "\eace";
}
.icon-grid-1:before {
  content: "\eacf";
}
.icon-grid-2:before {
  content: "\ead0";
}
.icon-grid-3:before {
  content: "\ead1";
}
.icon-grid-4:before {
  content: "\ead2";
}
.icon-grid-5:before {
  content: "\ead3";
}
.icon-grid-6:before {
  content: "\ead4";
}
.icon-grid-7:before {
  content: "\ead5";
}
.icon-grid-8:before {
  content: "\ead6";
}
.icon-grid-9:before {
  content: "\ead7";
}
.icon-grid-edit:before {
  content: "\ead8";
}
.icon-grid-eraser:before {
  content: "\ead9";
}
.icon-grid-lock:before {
  content: "\eada";
}
.icon-happyemoji:before {
  content: "\eadb";
}
.icon-hashtag-down:before {
  content: "\eadc";
}
.icon-hashtag-up:before {
  content: "\eadd";
}
.icon-hashtag1:before {
  content: "\eade";
}
.icon-heart-add:before {
  content: "\eadf";
}
.icon-heart-circle:before {
  content: "\eae0";
}
.icon-heart-edit:before {
  content: "\eae1";
}
.icon-heart-remove:before {
  content: "\eae2";
}
.icon-heart-search:before {
  content: "\eae3";
}
.icon-heart-slash:before {
  content: "\eae4";
}
.icon-heart-tick:before {
  content: "\eae5";
}
.icon-heart:before {
  content: "\eae6";
}
.icon-hierarchy-2:before {
  content: "\eae7";
}
.icon-hierarchy-3:before {
  content: "\eae8";
}
.icon-hierarchy-square-2:before {
  content: "\eae9";
}
.icon-hierarchy-square-3:before {
  content: "\eaea";
}
.icon-hierarchy-square:before {
  content: "\eaeb";
}
.icon-hierarchy:before {
  content: "\eaec";
}
.icon-home-1:before {
  content: "\eaed";
}
.icon-home-2:before {
  content: "\eaee";
}
.icon-home-wifi:before {
  content: "\eaef";
}
.icon-home:before {
  content: "\eaf0";
}
.icon-info-circle-2:before {
  content: "\eaf1";
}
.icon-info-circle:before {
  content: "\eaf2";
}
.icon-information:before {
  content: "\eaf3";
}
.icon-instagram:before {
  content: "\eaf4";
}
.icon-judge:before {
  content: "\eaf5";
}
.icon-kanban:before {
  content: "\eaf6";
}
.icon-key-square:before {
  content: "\eaf7";
}
.icon-key:before {
  content: "\eaf8";
}
.icon-lamp1:before {
  content: "\eaf9";
}
.icon-layer:before {
  content: "\eafa";
}
.icon-level:before {
  content: "\eafb";
}
.icon-lifebuoy:before {
  content: "\eafc";
}
.icon-like-1:before {
  content: "\eafd";
}
.icon-like-dislike:before {
  content: "\eafe";
}
.icon-like-shapes:before {
  content: "\eaff";
}
.icon-like-tag:before {
  content: "\eb00";
}
.icon-like:before {
  content: "\eb01";
}
.icon-location-add:before {
  content: "\eb02";
}
.icon-location-cross:before {
  content: "\eb03";
}
.icon-location-minus:before {
  content: "\eb04";
}
.icon-location-slash:before {
  content: "\eb05";
}
.icon-location-tick:before {
  content: "\eb06";
}
.icon-location:before {
  content: "\eb07";
}
.icon-lock-1:before {
  content: "\eb08";
}
.icon-lock-circle:before {
  content: "\eb09";
}
.icon-lock-slash:before {
  content: "\eb0a";
}
.icon-lock:before {
  content: "\eb0b";
}
.icon-lovely:before {
  content: "\eb0c";
}
.icon-magic-star:before {
  content: "\eb0d";
}
.icon-magicpen:before {
  content: "\eb0e";
}
.icon-main-component:before {
  content: "\eb0f";
}
.icon-map-1:before {
  content: "\eb10";
}
.icon-map:before {
  content: "\eb11";
}
.icon-mask-1:before {
  content: "\eb12";
}
.icon-mask-2:before {
  content: "\eb13";
}
.icon-mask:before {
  content: "\eb14";
}
.icon-maximize-1:before {
  content: "\eb15";
}
.icon-maximize-2:before {
  content: "\eb16";
}
.icon-maximize-3:before {
  content: "\eb17";
}
.icon-maximize-4:before {
  content: "\eb18";
}
.icon-maximize:before {
  content: "\eb19";
}
.icon-medal-star:before {
  content: "\eb1a";
}
.icon-medal:before {
  content: "\eb1b";
}
.icon-menu-board:before {
  content: "\eb1c";
}
.icon-menu1:before {
  content: "\eb1d";
}
.icon-message-2:before {
  content: "\eb1e";
}
.icon-message-add-1:before {
  content: "\eb1f";
}
.icon-message-add:before {
  content: "\eb20";
}
.icon-message-circle:before {
  content: "\eb21";
}
.icon-message-edit:before {
  content: "\eb22";
}
.icon-message-favorite:before {
  content: "\eb23";
}
.icon-message-minus:before {
  content: "\eb24";
}
.icon-message-notif:before {
  content: "\eb25";
}
.icon-message-programming:before {
  content: "\eb26";
}
.icon-message-question:before {
  content: "\eb27";
}
.icon-message-remove:before {
  content: "\eb28";
}
.icon-message-search:before {
  content: "\eb29";
}
.icon-message-square:before {
  content: "\eb2a";
}
.icon-message-text-1:before {
  content: "\eb2b";
}
.icon-message-text:before {
  content: "\eb2c";
}
.icon-message-tick:before {
  content: "\eb2d";
}
.icon-message-time:before {
  content: "\eb2e";
}
.icon-message:before {
  content: "\eb2f";
}
.icon-messages-1:before {
  content: "\eb30";
}
.icon-messages-2:before {
  content: "\eb31";
}
.icon-messages-3:before {
  content: "\eb32";
}
.icon-messages:before {
  content: "\eb33";
}
.icon-milk:before {
  content: "\eb34";
}
.icon-minus-cirlce:before {
  content: "\eb35";
}
.icon-minus-square:before {
  content: "\eb36";
}
.icon-minus:before {
  content: "\eb37";
}
.icon-mirror:before {
  content: "\eb38";
}
.icon-mobile-programming:before {
  content: "\eb39";
}
.icon-more-circle:before {
  content: "\eb3a";
}
.icon-more-square:before {
  content: "\eb3b";
}
.icon-mouse-circle:before {
  content: "\eb3c";
}
.icon-mouse-square:before {
  content: "\eb3d";
}
.icon-mouse1:before {
  content: "\eb3e";
}
.icon-note-1:before {
  content: "\eb3f";
}
.icon-note-2:before {
  content: "\eb40";
}
.icon-note-21:before {
  content: "\eb41";
}
.icon-note-add:before {
  content: "\eb42";
}
.icon-note-favorite:before {
  content: "\eb43";
}
.icon-note-remove:before {
  content: "\eb44";
}
.icon-note-text:before {
  content: "\eb45";
}
.icon-note:before {
  content: "\eb46";
}
.icon-omega-circle:before {
  content: "\eb47";
}
.icon-omega-square:before {
  content: "\eb48";
}
.icon-paintbucket:before {
  content: "\eb49";
}
.icon-password-check:before {
  content: "\eb4a";
}
.icon-path-2:before {
  content: "\eb4b";
}
.icon-path-square:before {
  content: "\eb4c";
}
.icon-path:before {
  content: "\eb4d";
}
.icon-pen-add:before {
  content: "\eb4e";
}
.icon-pen-close:before {
  content: "\eb4f";
}
.icon-pen-remove:before {
  content: "\eb50";
}
.icon-pen-tool-2:before {
  content: "\eb51";
}
.icon-pen-tool:before {
  content: "\eb52";
}
.icon-pet:before {
  content: "\eb53";
}
.icon-picture-frame:before {
  content: "\eb54";
}
.icon-pin:before {
  content: "\eb55";
}
.icon-radar-1:before {
  content: "\eb58";
}
.icon-radar-2:before {
  content: "\eb59";
}
.icon-radar:before {
  content: "\eb5a";
}
.icon-ranking-1:before {
  content: "\eb5b";
}
.icon-ranking:before {
  content: "\eb5c";
}
.icon-recovery-convert:before {
  content: "\eb5d";
}
.icon-reserve:before {
  content: "\eb5e";
}
.icon-rotate-left:before {
  content: "\eb5f";
}
.icon-rotate-right:before {
  content: "\eb60";
}
.icon-route-square:before {
  content: "\eb61";
}
.icon-routing-2:before {
  content: "\eb62";
}
.icon-routing:before {
  content: "\eb63";
}
.icon-row-horizontal:before {
  content: "\eb64";
}
.icon-row-vertical:before {
  content: "\eb65";
}
.icon-ruler:before {
  content: "\eb66";
}
.icon-rulerpen:before {
  content: "\eb67";
}
.icon-safe-home:before {
  content: "\eb68";
}
.icon-scan-barcode:before {
  content: "\eb69";
}
.icon-scan:before {
  content: "\eb6a";
}
.icon-scanner:before {
  content: "\eb6b";
}
.icon-scanning:before {
  content: "\eb6c";
}
.icon-scissor:before {
  content: "\eb6d";
}
.icon-scroll:before {
  content: "\eb6e";
}
.icon-security-safe:before {
  content: "\eb6f";
}
.icon-security-user:before {
  content: "\eb70";
}
.icon-security:before {
  content: "\eb71";
}
.icon-send-2:before {
  content: "\eb72";
}
.icon-send:before {
  content: "\eb73";
}
.icon-shapes-1:before {
  content: "\eb74";
}
.icon-shapes:before {
  content: "\eb75";
}
.icon-share:before {
  content: "\eb76";
}
.icon-shield-cross:before {
  content: "\eb77";
}
.icon-shield-search:before {
  content: "\eb78";
}
.icon-shield-slash:before {
  content: "\eb79";
}
.icon-shield-tick:before {
  content: "\eb7a";
}
.icon-shield:before {
  content: "\eb7b";
}
.icon-shop-add:before {
  content: "\eb7c";
}
.icon-shop-remove:before {
  content: "\eb7d";
}
.icon-shop:before {
  content: "\eb7e";
}
.icon-shopping-bag:before {
  content: "\eb7f";
}
.icon-shopping-cart:before {
  content: "\eb80";
}
.icon-sidebar-bottom:before {
  content: "\eb81";
}
.icon-sidebar-left:before {
  content: "\eb82";
}
.icon-sidebar-right:before {
  content: "\eb83";
}
.icon-sidebar-top:before {
  content: "\eb84";
}
.icon-signpost:before {
  content: "\eb85";
}
.icon-size:before {
  content: "\eb86";
}
.icon-slash:before {
  content: "\eb87";
}
.icon-slider-horizontal-1:before {
  content: "\eb88";
}
.icon-slider-horizontal:before {
  content: "\eb89";
}
.icon-slider-vertical-1:before {
  content: "\eb8a";
}
.icon-slider-vertical:before {
  content: "\eb8b";
}
.icon-slider:before {
  content: "\eb8c";
}
.icon-smart-home:before {
  content: "\eb8d";
}
.icon-smileys:before {
  content: "\eb8e";
}
.icon-sms-edit:before {
  content: "\eb8f";
}
.icon-sms-notification:before {
  content: "\eb90";
}
.icon-sms-search:before {
  content: "\eb91";
}
.icon-sms-star:before {
  content: "\eb92";
}
.icon-sms-tracking:before {
  content: "\eb93";
}
.icon-sms:before {
  content: "\eb94";
}
.icon-sort:before {
  content: "\eb95";
}
.icon-sound:before {
  content: "\eb96";
}
.icon-speedometer:before {
  content: "\eb97";
}
.icon-star-1:before {
  content: "\eb98";
}
.icon-star-slash:before {
  content: "\eb99";
}
.icon-star:before {
  content: "\eb9a";
}
.icon-status:before {
  content: "\eb9b";
}
.icon-sticker:before {
  content: "\eb9c";
}
.icon-stickynote:before {
  content: "\eb9d";
}
.icon-story:before {
  content: "\eb9e";
}
.icon-tag-cross:before {
  content: "\eb9f";
}
.icon-tag-right:before {
  content: "\eba0";
}
.icon-task-square:before {
  content: "\eba1";
}
.icon-task:before {
  content: "\eba2";
}
.icon-teacher:before {
  content: "\eba3";
}
.icon-tick-circle:before {
  content: "\eba4";
}
.icon-tick-square:before {
  content: "\eba5";
}
.icon-trade:before {
  content: "\eba6";
}
.icon-trash:before {
  content: "\eba7";
}
.icon-tree:before {
  content: "\eba8";
}
.icon-triangle1:before {
  content: "\eba9";
}
.icon-truck-fast:before {
  content: "\ebaa";
}
.icon-truck-remove:before {
  content: "\ebab";
}
.icon-truck-tick:before {
  content: "\ebac";
}
.icon-truck-time:before {
  content: "\ebad";
}
.icon-truck:before {
  content: "\ebae";
}
.icon-trush-square:before {
  content: "\ebaf";
}
.icon-unlimited:before {
  content: "\ebb0";
}
.icon-unlock:before {
  content: "\ebb1";
}
.icon-verify:before {
  content: "\ebb2";
}
.icon-warning-2:before {
  content: "\ebb3";
}
.icon-weight1:before {
  content: "\ebb4";
}
.icon-wifi-square:before {
  content: "\ebb5";
}
.icon-wifi:before {
  content: "\ebb6";
}
.icon-full:before {
  content: "\ebb7";
}
.icon-airplane-square:before {
  content: "\ebb8";
}
.icon-airplane:before {
  content: "\ebb9";
}
.icon-bus:before {
  content: "\ebba";
}
.icon-car:before {
  content: "\ebbb";
}
.icon-driving:before {
  content: "\ebbc";
}
.icon-gas-station:before {
  content: "\ebbd";
}
.icon-ship:before {
  content: "\ebbe";
}
.icon-smart-car:before {
  content: "\ebbf";
}
.icon-frame1:before {
  content: "\ebc0";
}
.icon-people:before {
  content: "\ebc1";
}
.icon-profile-2user:before {
  content: "\ebc2";
}
.icon-profile-add:before {
  content: "\ebc3";
}
.icon-profile-circle:before {
  content: "\ebc4";
}
.icon-profile-delete:before {
  content: "\ebc5";
}
.icon-profile-remove:before {
  content: "\ebc6";
}
.icon-profile-tick:before {
  content: "\ebc7";
}
.icon-tag-user:before {
  content: "\ebc8";
}
.icon-user-add:before {
  content: "\ebc9";
}
.icon-user-cirlce-add:before {
  content: "\ebca";
}
.icon-user-edit:before {
  content: "\ebcb";
}
.icon-user-minus:before {
  content: "\ebcc";
}
.icon-user-octagon:before {
  content: "\ebcd";
}
.icon-user-remove:before {
  content: "\ebce";
}
.icon-user-search:before {
  content: "\ebcf";
}
.icon-user-square:before {
  content: "\ebd0";
}
.icon-user-tag:before {
  content: "\ebd1";
}
.icon-user-tick:before {
  content: "\ebd2";
}
.icon-user:before {
  content: "\ebd3";
}
.icon-arrange-circle-2:before {
  content: "\ebd4";
}
.icon-arrange-circle:before {
  content: "\ebd5";
}
.icon-arrange-square-2:before {
  content: "\ebd6";
}
.icon-arrange-square:before {
  content: "\ebd7";
}
.icon-back-square:before {
  content: "\ebfb";
}
.icon-convert:before {
  content: "\ebfc";
}
.icon-export-1:before {
  content: "\ebfd";
}
.icon-export-2:before {
  content: "\ebfe";
}
.icon-export-3:before {
  content: "\ebff";
}
.icon-export:before {
  content: "\ec00";
}
.icon-forward-square:before {
  content: "\ec01";
}
.icon-frame2:before {
  content: "\ec02";
}
.icon-import-1:before {
  content: "\ec03";
}
.icon-import-2:before {
  content: "\ec04";
}
.icon-import:before {
  content: "\ec05";
}
.icon-login-1:before {
  content: "\ec06";
}
.icon-login:before {
  content: "\ec07";
}
.icon-logout-1:before {
  content: "\ec08";
}
.icon-logout:before {
  content: "\ec09";
}
.icon-receive-square-2:before {
  content: "\ec0a";
}
.icon-receive-square:before {
  content: "\ec0b";
}
.icon-received:before {
  content: "\ec0c";
}
.icon-redo:before {
  content: "\ec0d";
}
.icon-refresh-2:before {
  content: "\ec0e";
}
.icon-refresh-circle:before {
  content: "\ec0f";
}
.icon-refresh-left-square:before {
  content: "\ec10";
}
.icon-refresh-right-square:before {
  content: "\ec11";
}
.icon-refresh-square-2:before {
  content: "\ec12";
}
.icon-refresh:before {
  content: "\ec13";
}
.icon-repeat-circle:before {
  content: "\ec14";
}
.icon-repeat:before {
  content: "\ec15";
}
.icon-reply:before {
  content: "\ec16";
}
.icon-rotate-left1:before {
  content: "\ec17";
}
.icon-rotate-right1:before {
  content: "\ec18";
}
.icon-send-sqaure-2:before {
  content: "\ec19";
}
.icon-send-square:before {
  content: "\ec1a";
}
.icon-send1:before {
  content: "\ec1b";
}
.icon-undo:before {
  content: "\ec1c";
}
.icon-audio-square:before {
  content: "\ec1d";
}
.icon-backward-5-seconds:before {
  content: "\ec1e";
}
.icon-backward-10-seconds:before {
  content: "\ec1f";
}
.icon-backward-15-seconds:before {
  content: "\ec20";
}
.icon-backward:before {
  content: "\ec21";
}
.icon-camera-slash:before {
  content: "\ec22";
}
.icon-camera:before {
  content: "\ec23";
}
.icon-devices1:before {
  content: "\ec24";
}
.icon-forward-5-seconds:before {
  content: "\ec25";
}
.icon-forward-10-seconds:before {
  content: "\ec26";
}
.icon-forward-15-seconds:before {
  content: "\ec27";
}
.icon-forward:before {
  content: "\ec28";
}
.icon-gallery-add:before {
  content: "\ec29";
}
.icon-gallery-edit:before {
  content: "\ec2a";
}
.icon-gallery-export:before {
  content: "\ec2b";
}
.icon-gallery-favorite:before {
  content: "\ec2c";
}
.icon-gallery-import:before {
  content: "\ec2d";
}
.icon-gallery-remove:before {
  content: "\ec2e";
}
.icon-gallery-slash:before {
  content: "\ec2f";
}
.icon-gallery-tick:before {
  content: "\ec30";
}
.icon-gallery:before {
  content: "\ec31";
}
.icon-image:before {
  content: "\ec32";
}
.icon-maximize-circle:before {
  content: "\ec33";
}
.icon-microphone-2:before {
  content: "\ec34";
}
.icon-microphone-slash-1:before {
  content: "\ec35";
}
.icon-microphone-slash:before {
  content: "\ec36";
}
.icon-microphone:before {
  content: "\ec37";
}
.icon-mini-music-sqaure:before {
  content: "\ec38";
}
.icon-music-circle:before {
  content: "\ec39";
}
.icon-music-dashboard:before {
  content: "\ec3a";
}
.icon-music-filter:before {
  content: "\ec3b";
}
.icon-music-library-2:before {
  content: "\ec3c";
}
.icon-music-playlist:before {
  content: "\ec3d";
}
.icon-music-square-add:before {
  content: "\ec3e";
}
.icon-music-square-remove:before {
  content: "\ec3f";
}
.icon-music-square-search:before {
  content: "\ec40";
}
.icon-music-square:before {
  content: "\ec41";
}
.icon-music:before {
  content: "\ec42";
}
.icon-musicnote:before {
  content: "\ec43";
}
.icon-next:before {
  content: "\ec44";
}
.icon-note-square:before {
  content: "\ec45";
}
.icon-pause-circle:before {
  content: "\ec46";
}
.icon-pause:before {
  content: "\ec47";
}
.icon-play-add:before {
  content: "\ec48";
}
.icon-play-circle:before {
  content: "\ec49";
}
.icon-play-cricle:before {
  content: "\ec4a";
}
.icon-play-remove:before {
  content: "\ec4b";
}
.icon-play:before {
  content: "\ec4c";
}
.icon-previous:before {
  content: "\ec4d";
}
.icon-radio:before {
  content: "\ec4e";
}
.icon-record-circle:before {
  content: "\ec4f";
}
.icon-record:before {
  content: "\ec50";
}
.icon-repeate-music:before {
  content: "\ec51";
}
.icon-repeate-one:before {
  content: "\ec52";
}
.icon-scissor1:before {
  content: "\ec53";
}
.icon-screenmirroring:before {
  content: "\ec54";
}
.icon-shuffle:before {
  content: "\ec55";
}
.icon-stop-circle:before {
  content: "\ec56";
}
.icon-stop:before {
  content: "\ec57";
}
.icon-subtitle:before {
  content: "\ec58";
}
.icon-video-add:before {
  content: "\ec59";
}
.icon-video-circle:before {
  content: "\ec5a";
}
.icon-video-horizontal:before {
  content: "\ec5b";
}
.icon-video-octagon:before {
  content: "\ec5c";
}
.icon-video-play:before {
  content: "\ec5d";
}
.icon-video-remove:before {
  content: "\ec5e";
}
.icon-video-slash:before {
  content: "\ec5f";
}
.icon-video-square:before {
  content: "\ec60";
}
.icon-video-tick:before {
  content: "\ec61";
}
.icon-video-time:before {
  content: "\ec62";
}
.icon-video-vertical:before {
  content: "\ec63";
}
.icon-video:before {
  content: "\ec64";
}
.icon-voice-cricle:before {
  content: "\ec65";
}
.icon-voice-square:before {
  content: "\ec66";
}
.icon-volume-cross:before {
  content: "\ec67";
}
.icon-volume-high:before {
  content: "\ec68";
}
.icon-volume-low-1:before {
  content: "\ec69";
}
.icon-volume-low:before {
  content: "\ec6a";
}
.icon-volume-mute:before {
  content: "\ec6b";
}
.icon-volume-slash:before {
  content: "\ec6c";
}
.icon-volume-up:before {
  content: "\ec6d";
}
.icon-folder-2:before {
  content: "\ec6e";
}
.icon-folder-add:before {
  content: "\ec6f";
}
.icon-folder-cloud:before {
  content: "\ec70";
}
.icon-folder-cross:before {
  content: "\ec71";
}
.icon-folder-favorite:before {
  content: "\ec72";
}
.icon-folder-minus:before {
  content: "\ec73";
}
.icon-folder-open:before {
  content: "\ec74";
}
.icon-folder:before {
  content: "\ec75";
}
.icon-cloud-cross:before {
  content: "\ec76";
}
.icon-cloud-drizzle:before {
  content: "\ec77";
}
.icon-cloud-fog:before {
  content: "\ec78";
}
.icon-cloud-lightning:before {
  content: "\ec79";
}
.icon-cloud-minus:before {
  content: "\ec7a";
}
.icon-cloud-notif:before {
  content: "\ec7b";
}
.icon-cloud-plus:before {
  content: "\ec7c";
}
.icon-cloud-snow:before {
  content: "\ec7d";
}
.icon-cloud-sunny:before {
  content: "\ec7e";
}
.icon-cloud:before {
  content: "\ec7f";
}
.icon-flash1:before {
  content: "\ec80";
}
.icon-icon1:before {
  content: "\ec81";
}
.icon-moon:before {
  content: "\ec82";
}
.icon-sun-1:before {
  content: "\ec83";
}
.icon-sun-fog:before {
  content: "\ec84";
}
.icon-sun:before {
  content: "\ec85";
}
.icon-wind-2:before {
  content: "\ec86";
}
.icon-wind:before {
  content: "\ec87";
}
.icon-attach-circle:before {
  content: "\ec88";
}
.icon-attach-square:before {
  content: "\ec89";
}
.icon-eraser1:before {
  content: "\ec8a";
}
.icon-firstline:before {
  content: "\ec8b";
}
.icon-language-circle:before {
  content: "\ec8c";
}
.icon-language-square:before {
  content: "\ec8d";
}
.icon-link-1:before {
  content: "\ec8e";
}
.icon-link-2:before {
  content: "\ec8f";
}
.icon-link-21:before {
  content: "\ec90";
}
.icon-link-circle:before {
  content: "\ec91";
}
.icon-link-square:before {
  content: "\ec92";
}
.icon-link:before {
  content: "\ec93";
}
.icon-maximize1:before {
  content: "\ec94";
}
.icon-paperclip-2:before {
  content: "\ec95";
}
.icon-paperclip:before {
  content: "\ec96";
}
.icon-pharagraphspacing:before {
  content: "\ec97";
}
.icon-quote-down-circle:before {
  content: "\ec98";
}
.icon-quote-down-square:before {
  content: "\ec99";
}
.icon-quote-down:before {
  content: "\ec9a";
}
.icon-quote-up-circle:before {
  content: "\ec9b";
}
.icon-quote-up-square:before {
  content: "\ec9c";
}
.icon-quote-up:before {
  content: "\ec9d";
}
.icon-smallcaps:before {
  content: "\ec9e";
}
.icon-text-block:before {
  content: "\ec9f";
}
.icon-text-bold:before {
  content: "\eca0";
}
.icon-text-italic:before {
  content: "\eca1";
}
.icon-text-underline:before {
  content: "\eca2";
}
.icon-text:before {
  content: "\eca3";
}
.icon-textalign-center:before {
  content: "\eca4";
}
.icon-textalign-justifycenter:before {
  content: "\eca5";
}
.icon-textalign-justifyleft:before {
  content: "\eca6";
}
.icon-textalign-justifyright:before {
  content: "\eca7";
}
.icon-textalign-left:before {
  content: "\eca8";
}
.icon-textalign-right:before {
  content: "\eca9";
}
.icon-translate:before {
  content: "\ecaa";
}
.icon-archive2:before {
  content: "\ecab";
}
.icon-card-add:before {
  content: "\ecac";
}
.icon-card-edit:before {
  content: "\ecad";
}
.icon-card-pos:before {
  content: "\ecae";
}
.icon-card-receive:before {
  content: "\ecaf";
}
.icon-card-remove-1:before {
  content: "\ecb0";
}
.icon-card-remove:before {
  content: "\ecb1";
}
.icon-card-send:before {
  content: "\ecb2";
}
.icon-card-slash:before {
  content: "\ecb3";
}
.icon-card-tick-1:before {
  content: "\ecb4";
}
.icon-card-tick:before {
  content: "\ecb5";
}
.icon-card:before {
  content: "\ecb6";
}
.icon-cards:before {
  content: "\ecb7";
}
.icon-chart-square:before {
  content: "\ecb8";
}
.icon-coin-1:before {
  content: "\ecb9";
}
.icon-coin:before {
  content: "\ecba";
}
.icon-convert-card:before {
  content: "\ecbb";
}
.icon-discount-circle:before {
  content: "\ecbc";
}
.icon-discount-shape:before {
  content: "\ecbd";
}
.icon-document1:before {
  content: "\ecbe";
}
.icon-dollar-circle:before {
  content: "\ecbf";
}
.icon-dollar-square:before {
  content: "\ecc0";
}
.icon-empty-wallet-add:before {
  content: "\ecc1";
}
.icon-empty-wallet-change:before {
  content: "\ecc2";
}
.icon-empty-wallet-remove:before {
  content: "\ecc3";
}
.icon-empty-wallet-tick:before {
  content: "\ecc4";
}
.icon-empty-wallet-time:before {
  content: "\ecc5";
}
.icon-empty-wallet:before {
  content: "\ecc6";
}
.icon-math:before {
  content: "\ecc7";
}
.icon-money-2:before {
  content: "\ecc8";
}
.icon-money-3:before {
  content: "\ecc9";
}
.icon-money-4:before {
  content: "\ecca";
}
.icon-money-add:before {
  content: "\eccb";
}
.icon-money-change:before {
  content: "\eccc";
}
.icon-money-forbidden:before {
  content: "\eccd";
}
.icon-money-recive:before {
  content: "\ecce";
}
.icon-money-remove:before {
  content: "\eccf";
}
.icon-money-send:before {
  content: "\ecd0";
}
.icon-money-tick:before {
  content: "\ecd1";
}
.icon-money-time:before {
  content: "\ecd2";
}
.icon-money:before {
  content: "\ecd3";
}
.icon-moneys:before {
  content: "\ecd4";
}
.icon-percentage-circle:before {
  content: "\ecd5";
}
.icon-percentage-square:before {
  content: "\ecd6";
}
.icon-receipt-1:before {
  content: "\ecd7";
}
.icon-receipt-2-1:before {
  content: "\ecd8";
}
.icon-receipt-2:before {
  content: "\ecd9";
}
.icon-receipt-add:before {
  content: "\ecda";
}
.icon-receipt-discount:before {
  content: "\ecdb";
}
.icon-receipt-disscount:before {
  content: "\ecdc";
}
.icon-receipt-edit:before {
  content: "\ecdd";
}
.icon-receipt-item:before {
  content: "\ecde";
}
.icon-receipt-minus:before {
  content: "\ecdf";
}
.icon-receipt-search:before {
  content: "\ece0";
}
.icon-receipt-text:before {
  content: "\ece1";
}
.icon-receipt:before {
  content: "\ece2";
}
.icon-security-card:before {
  content: "\ece3";
}
.icon-strongbox-2:before {
  content: "\ece4";
}
.icon-strongbox:before {
  content: "\ece5";
}
.icon-tag-2:before {
  content: "\ece6";
}
.icon-tag:before {
  content: "\ece7";
}
.icon-ticket-2:before {
  content: "\ece8";
}
.icon-ticket-discount:before {
  content: "\ece9";
}
.icon-ticket-expired:before {
  content: "\ecea";
}
.icon-ticket-star:before {
  content: "\eceb";
}
.icon-ticket:before {
  content: "\ecec";
}
.icon-transaction-minus:before {
  content: "\eced";
}
.icon-wallet-1:before {
  content: "\ecee";
}
.icon-wallet-2:before {
  content: "\ecef";
}
.icon-wallet-3:before {
  content: "\ecf0";
}
.icon-wallet-add-1:before {
  content: "\ecf1";
}
.icon-wallet-add:before {
  content: "\ecf2";
}
.icon-wallet-check:before {
  content: "\ecf3";
}
.icon-wallet-minus:before {
  content: "\ecf4";
}
.icon-wallet-money:before {
  content: "\ecf5";
}
.icon-wallet-remove:before {
  content: "\ecf6";
}
.icon-wallet-search:before {
  content: "\ecf7";
}
.icon-wallet:before {
  content: "\ecf8";
}
.icon-arrow-right1:before {
  content: "\ecfa";
}
.icon-arrow-left1:before {
  content: "\ecfb";
}
.icon-arrow-down1:before {
  content: "\ecfc";
}
.icon-arrow-up1:before {
  content: "\ecfd";
}
.icon-arrow-square:before {
  content: "\ea1d";
}
.icon-arrow:before {
  content: "\ea1e";
}
.icon-programming-arrow:before {
  content: "\eb56";
}
.icon-programming-arrows:before {
  content: "\eb57";
}
.icon-arrow-2:before {
  content: "\ebd8";
}
.icon-arrow-3:before {
  content: "\ebd9";
}
.icon-arrow-bottom:before {
  content: "\ebda";
}
.icon-arrow-circle-down:before {
  content: "\ebdb";
}
.icon-arrow-circle-left:before {
  content: "\ebdc";
}
.icon-arrow-circle-right:before {
  content: "\ebdd";
}
.icon-arrow-circle-up:before {
  content: "\ebde";
}
.icon-arrow-down-1:before {
  content: "\ebdf";
}
.icon-arrow-down-2:before {
  content: "\ebe0";
}
.icon-arrow-down:before {
  content: "\ebe1";
}
.icon-arrow-left-1:before {
  content: "\ebe2";
}
.icon-arrow-left-2:before {
  content: "\ebe3";
}
.icon-arrow-left-3:before {
  content: "\ebe4";
}
.icon-arrow-left-21:before {
  content: "\ebe5";
}
.icon-arrow-left:before {
  content: "\ebe6";
}
.icon-arrow-right-1:before {
  content: "\ebe7";
}
.icon-arrow-right-2-1:before {
  content: "\ebe8";
}
.icon-arrow-right-2:before {
  content: "\ebe9";
}
.icon-arrow-right-3:before {
  content: "\ebea";
}
.icon-arrow-right-4:before {
  content: "\ebeb";
}
.icon-arrow-right-21:before {
  content: "\ebec";
}
.icon-arrow-right:before {
  content: "\ebed";
}
.icon-arrow-square-down-2:before {
  content: "\ebee";
}
.icon-arrow-square-down:before {
  content: "\ebef";
}
.icon-arrow-square-left-2:before {
  content: "\ebf0";
}
.icon-arrow-square-left:before {
  content: "\ebf1";
}
.icon-arrow-square-up-down:before {
  content: "\ebf2";
}
.icon-arrow-square-up-2:before {
  content: "\ebf3";
}
.icon-arrow-square-up:before {
  content: "\ebf4";
}
.icon-arrow-swap-horizontal:before {
  content: "\ebf5";
}
.icon-arrow-swap:before {
  content: "\ebf6";
}
.icon-arrow-up-1:before {
  content: "\ebf7";
}
.icon-arrow-up-2:before {
  content: "\ebf8";
}
.icon-arrow-up-3:before {
  content: "\ebf9";
}
.icon-arrow-up:before {
  content: "\ebfa";
}
.icon-p-critical:before {
  content: "\e919";
}
