.text-grey-50 {
    color: var(--grey-50);
}

.text-grey-100 {
    color: var(--grey-100);
}

.text-grey-200 {
    color: var(--grey-200);
}

.text-grey-300 {
    color: var(--grey-300);
}

.text-grey-400 {
    color: var(--grey-400);
}

.text-grey-500 {
    color: var(--grey-500);
}

.text-grey-600 {
    color: var(--grey-600);
}

.text-grey-700 {
    color: var(--grey-700);
}

.text-grey-800 {
    color: var(--grey-800);
}

.text-grey-900 {
    color: var(--grey-900);
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-dark {
    color: var(--primary);
}

.error-message {
    font-size: 0.75rem;
    color: var(--accent-red);
}
